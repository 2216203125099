/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from "react";

import * as CTypes from "../types";

/** React Context object for Chat SDK (docstrings in ChatContext type) */
const Context = createContext<CTypes.SDK>({
  addContact: (id) => {},
  createGroupChat: (ids, name) => {
    return new Promise((resolve, reject) => {
      resolve("");
    });
  },
  createInstantMeeting: async () => {
    return "";
  },
  guestLogin: async () => {},
  removeContact: (user_id) => {},
  sendFile: (id, file) => {},
  sendGroupChatInvite: (id, roomID) => {},
  sendInstantMeetingInvite: (user_id) => {},
  sendInstantMeetingResponse: (meeting_id, reseponse) => {},
  sendTextMessage: (user_id, text) => {},
  updateCustomStatus: (status) => {
    return new Promise((resolve, reject) => resolve());
  },
  updatePresence: (presence) => {},
  isSignedIn: false,
  currentUser: null,
  contacts: {},
  groupChats: {},
  mjwt: null,
  rooms: {},
  sentInstantMeetingInvites: [],
  receivedInstantMeetingInvites: [],
  getChatHistory: async (id) => {},
});

export default Context;
