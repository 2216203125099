import * as XMPP from "stanza";

import * as CTypes from "../types";
import { SDKActivationError } from "@chat/_errors";

/** Recursively retrieve full message archive */
const getAllMessages = async (props: {
  _websocket: CTypes.Websocket;
  after?: any;
}) => {
  const { _websocket } = { ...props };
  const after = props.after || undefined;

  // Validate state
  if (!_websocket) {
    throw new SDKActivationError(
      "Can't retrieve message archive, no XMPP websocket."
    );
  }

  // Create archive request args
  const paging = after ? { after } : {};

  // Make archive request
  const res = await _websocket.searchHistory({ paging });
  const {
    complete,
    // @ts-ignore
    paging: { last },
  } = res;

  // Recurse if archive isn't complete
  if (!complete) {
    getAllMessages({ _websocket, after: last });
  }
};

export default getAllMessages;
