import "../styles/globals.css";
import "../styles/fonts/fonts.css";
import type { AppProps } from "next/app";
import Head from "next/head";
import { ThemeProvider } from "@mui/material";

import theme from "../@components/VisionableTheme";
import { awsAmplifyConfig } from "../@config";
import { ChatProvider } from "../@chat";
// import { appWithTranslation } from "next-i18next";

import { getServiceUrls } from "@utilities";

awsAmplifyConfig();

function VisionableApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Visionable</title>
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1, user-scalable=no, viewport-fit=cover"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="format-detection" content="telephone=no" />
      </Head>

      <ChatProvider
        xmppServer={{
          hostname: getServiceUrls().msg,
          mucHostname: `muclight.${getServiceUrls().msg}`,
          protocol: "wss",
          endpoint: "ws-xmpp",
          port: "443",
        }}
      >
        <ThemeProvider theme={theme}>
          <Component {...pageProps} />
        </ThemeProvider>
      </ChatProvider>
    </>
  );
}

export default VisionableApp;
