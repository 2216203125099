import { DataError, SDKActivationError } from "@chat/_errors";
import * as CTypes from "../types";
import * as _utils from "../_utils";

const createGroupChat = async ({
  ids,
  name,
  isSignedIn,
  _status,
  _websocket,
  currentUser,
  xmppServer,
}: {
  ids: string[];
  name: string | undefined;
  isSignedIn: CTypes.SDK["isSignedIn"];
  _status: CTypes.LoadStatus;
  _websocket: CTypes.Websocket;
  currentUser: CTypes.Contact | null;
  xmppServer: CTypes.XMPPServerData;
}) => {
  // Validate state
  if (!isSignedIn || _status !== "active" || !_websocket) {
    throw new SDKActivationError("Can't create group chat, not signed in.");
  }
  if (!currentUser) {
    throw new DataError("Can't create group chat, no current user data.");
  }

  // Create MUC room
  // @ts-ignore
  const uuid = crypto.randomUUID() || "";
  const jid = _utils.formatJID(uuid, xmppServer, true);
  const room = await _websocket.joinRoom(jid, currentUser.full_name);
  _websocket.configureRoom(jid, {
    fields: [{ name: "roomname", value: name }],
  });

  // Invite users
  ids.forEach((id) => {
    _websocket.setRoomAffiliation(
      jid,
      _utils.formatJID(id, xmppServer),
      "member"
    );
  });

  // Return uuid of room
  return uuid;
};

export default createGroupChat;
