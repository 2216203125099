/** Errors related to chat rooms */
class RoomError extends Error {
  constructor(message: string) {
    super(message);

    this.name = "RoomError";
  }
}

export default RoomError;
