import * as XMPP from "stanza";

import * as CTypes from "../types";
import * as _utils from "../_utils";
import * as _log from "../_log";
import { SDKActivationError, XMPPStreamError } from "../_errors";

const updateRoster = async (props: {
  _websocket: CTypes.Websocket;
  contacts: CTypes.ContactList;
}) => {
  const { _websocket, contacts } = { ...props };

  // Validate state
  if (!_websocket) {
    throw new SDKActivationError("Can't update roster, no XMPP websocket");
  }

  // Retrieve roster
  const roster = (await _websocket.getRoster()).items;
  if (!roster) {
    throw new XMPPStreamError(
      "Can't update roster, couldn't retrieve roster data."
    );
  }

  // New contacts / group chats dictionaries
  const tmpGroupChats: CTypes.GroupChatList = {};
  const tmpContacts: CTypes.ContactList = { ...contacts };

  // Update contacts' subscription info
  roster.forEach((rosterItem) => {
    const jid = rosterItem.jid;
    const id = _utils.formatID(jid);

    // Group chat subscription
    if (rosterItem.groups?.length) {
      tmpGroupChats[id] = _utils.formatGroupChat(
        jid,
        [],
        rosterItem.name || "unnamed"
      );
    }

    // Contacts / single user subscription
    else if (`${id}` in tmpContacts) {
      switch (rosterItem.subscription) {
        // Active mutual subscription
        case "both":
          tmpContacts[id] = { ...tmpContacts[id], contact: true };
          break;

        // Incoming contact subscription request
        case "from":
          // no longer auto adding back
          // _websocket.subscribe(jid);
          break;

        // Outgoing subscription request
        case "to":
          tmpContacts[id] = { ...tmpContacts[id], contact: true };
          break;

        // Other case (Something went wrong if this runs)
        default:
          _log.warn("There was an issue with a roster item...");
          break;
      }
    }

    // Invalid or unrecognized ID in roster item
    else {
      _log.warn("Roster item doesn't map to group chat or account user...");
    }
  });

  // Return updated states
  return {
    contacts: tmpContacts,
    groupChats: tmpGroupChats,
  };
};

export default updateRoster;
