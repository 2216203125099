/** Errors related to API authentication */
class APIAuthError extends Error {
  constructor(message: string) {
    const errorMessage = `${message} User not authenticated through v3 API`;
    super(errorMessage);

    this.name = "APIAuthError";
  }
}

export default APIAuthError;
