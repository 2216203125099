import * as XMPP from "stanza";
import * as CTypes from "../types";
import { DataError } from "../_errors";
import * as _utils from "../_utils";
import * as _log from "../_log";

const onAvailable = ({
  data,
  currentUser,
  contacts,
}: {
  data: XMPP.Stanzas.ReceivedPresence;
  currentUser: CTypes.SDK["currentUser"];
  contacts: CTypes.SDK["contacts"];
}) => {
  // Validate state
  if (!currentUser) {
    throw new DataError(
      "Couldn't handle 'available' XMPP event. No current user data."
    );
  }

  // New states
  const newCurrentUser = { ...currentUser };
  const newContacts = { ...contacts };

  // Get id of presence update's user
  const from = _utils.formatID(data.from);

  // Handle current user presence update (if applicable)
  if (from === newCurrentUser.user_id) {
    newCurrentUser.presence = "available";
  }

  // Handle other user's presence update (if applicable)
  else if (Object.keys(newContacts).includes(from)) {
    newContacts[from].presence = "available";
  }

  // If id not found in user's (this should never run!)
  else {
    _log.warn(`Couldn't find user ${from} from 'available' presence update.`);
  }

  // Return updated states
  return {
    currentUser: newCurrentUser,
    contacts: newContacts,
  };
};

export default onAvailable;
