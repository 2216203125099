import { getServiceUrls } from "@utilities";
import { getCurrentUserInfo } from "../../@cognito";

/** Retrieve the "Inbox" */
const getInbox = async () => {
  const url = `${getServiceUrls().api}/api/messages/inbox`;
  // TODO Guest
  const user = await getCurrentUserInfo();
  const jwt = user.signInUserSession.idToken.jwtToken;

  try {
    const res = await fetch(url, {
      method: "GET",
      headers: { Authorization: jwt },
    });
    return res.json();
  } catch (e) {
    console.log("Error fetching inbox", e);
  }
};

export default getInbox;
