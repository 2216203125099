/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable valid-jsdoc */
/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import * as XMPP from "stanza";

import * as _log from "../_log";
import * as _utils from "../_utils";
import * as _api from "../_api";
import * as _private from "../_private";
import * as _public from "../_public";
import * as _xmpp from "../_xmpp";
import {
  APIAuthError,
  SDKActivationError,
  SyncError,
  UserError,
  XMPPAuthError,
  XMPPStreamError,
  MessageError,
} from "../_errors";
import { useStateRef } from "@utilities";
import Context from "../ChatContext";
import * as CTypes from "../types";
import { authStore, userStore } from "@state/store";

/**
 * React Context Provider component built to encapsulate the app, just below
 * the global state provider
 *
 * (Check the Chat SDK README for more information.)
 */
const ChatProvider = ({
  children,
  xmppServer,
}: {
  children: React.ReactNode;

  /** Information about the XMPP server to connect to */
  xmppServer: CTypes.XMPPServerData;
}) => {
  const router = useRouter();
  const { accountUsers } = userStore();
  const globalAuth = authStore();

  //////////////////////////////////////////////////////////////////////////////
  //
  //  CHAT SDK DATA
  //
  //////////////////////////////////////////////////////////////////////////////

  // XMPP agent / websocket data
  const [_websocket, _setWebsocket, _websocketRef] =
    useStateRef<XMPP.Agent | null>(null);
  const [_websocketActive, _setWebsocketActive, _websocketActiveRef] =
    useStateRef(false);

  // XMPP data
  const [_roster, _setRoster, _rosterRef] = useStateRef<
    XMPP.Stanzas.RosterItem[]
  >([]);
  const [_fileUploadHost, _setFileUploadHost, _fileUploadHostRef] = useStateRef<
    string | null
  >(null);

  // SDK status flags
  const [_restarting, _setRestarting, _restartingRef] = useStateRef(false);
  const [_status, _setStatus, _statusRef] =
    useStateRef<CTypes.LoadStatus>("inactive");
  const [
    _loadStepOneComplete,
    _setLoadStepOneComplete,
    _loadStepOneCompleteRef,
  ] = useStateRef(false);
  const [
    _loadStepTwoComplete,
    _setLoadStepTwoComplete,
    _loadStepTwoCompleteRef,
  ] = useStateRef(false);
  const [
    _loadStepThreeComplete,
    _setLoadStepThreeComplete,
    _loadStepThreeCompleteRef,
  ] = useStateRef(false);
  const [
    _unloadStepOneComplete,
    _setUnloadStepOneComplete,
    _unloadStepOneCompleteRef,
  ] = useStateRef(false);
  const [
    _unloadStepTwoComplete,
    _setUnloadStepTwoComplete,
    _unloadStepTwoCompleteRef,
  ] = useStateRef(false);
  const [_rosterLoaded, _setRosterLoaded, _rosterLoadedRef] =
    useStateRef(false);

  // SDK data
  const [currentUser, _setCurrentUser, _currentUserRef] =
    useStateRef<CTypes.Contact | null>(null);
  const [contacts, _setContacts, _contactsRef] =
    useStateRef<CTypes.ContactList>({});
  const [groupChats, _setGroupChats, _groupChatsRef] =
    useStateRef<CTypes.GroupChatList>({});
  const [isSignedIn, _setIsSignedIn, _isSignedInRef] = useStateRef(false);
  const [mjwt, _setMjwt, _mjwtRef] = useStateRef<CTypes.SDK["mjwt"]>(null);
  const [rooms, _setRooms, _roomsRef] = useStateRef<CTypes.RoomList>({});
  const [
    sentInstantMeetingInvites,
    _setSentInstantMeetingInvites,
    _sentInstantMeetingInvitesRef,
  ] = useStateRef<CTypes.SentInstantMeetingInvite[]>([]);
  const [
    receivedInstantMeetingInvites,
    _setReceivedInstantMeetingInvites,
    _receivedInstantMeetingInvitesRef,
  ] = useStateRef<CTypes.ReceivedInstantMeetingInvite[]>([]);

  //////////////////////////////////////////////////////////////////////////////
  //  END CHAT SDK DATA
  //////////////////////////////////////////////////////////////////////////////
  //
  //  PUBLIC FUNCTIONS (function docstrings in types)
  //
  //////////////////////////////////////////////////////////////////////////////

  const addContact: CTypes.SDK["addContact"] = (
    /** User ID of contact to add */
    id
  ) => {
    _log.debug(`Adding ${id} to contacts...`);

    // Get current state values
    const isSignedIn = _isSignedInRef.current;
    const _status = _statusRef.current;
    const _websocket = _websocketRef.current;
    const contacts = _contactsRef.current;

    // Add contact
    try {
      _public.addContact({
        id,
        isSignedIn,
        _status,
        _websocket,
        contacts,
        xmppServer,
      });
    } catch (error) {
      _log.error(error);
      throw error;
    }

    _log.debug(`Contact roster request sent to ${id}!`);
  };

  const createGroupChat: CTypes.SDK["createGroupChat"] = async (
    /** IDs of all users to add to group chat */
    ids,

    /** Name of group chat */
    name
  ) => {
    _log.debug("Creating group chat...");

    // Get current state values
    const isSignedIn = _isSignedInRef.current;
    const _status = _statusRef.current;
    const _websocket = _websocketRef.current;
    const currentUser = _currentUserRef.current;

    // Create group chat
    let uuid: string;
    try {
      uuid = await _public.createGroupChat({
        ids,
        name,
        isSignedIn,
        _status,
        _websocket,
        currentUser,
        xmppServer,
      });
    } catch (error) {
      _log.error(error);
      throw error;
    }

    return uuid;
  };

  const createInstantMeeting: CTypes.SDK["createInstantMeeting"] = async () => {
    _log.debug("Creating blank instant meeting...");

    // Get current state values
    const isSignedIn = _isSignedInRef.current;
    const _status = _statusRef.current;
    const currentUser = _currentUserRef.current;

    let meetingId: string;
    try {
      meetingId = await _public.createInstantMeeting({
        isSignedIn,
        currentUser,
      });

      return meetingId;
    } catch (error) {
      return "";
    }
  };

  const guestLogin: CTypes.SDK["guestLogin"] = async (meetingId, name) => {
    _log.debug("Starting guest login...");

    // Get current state values
    const _status = _statusRef.current;

    // Guest login
    const login = await _public.guestLogin({ meetingId, name, _status });

    // Update state
    if (login) {
      _setStatus("active");

      // TODO deconstruct mjwt for guest user data
    }
    return;
  };

  const removeContact: CTypes.SDK["removeContact"] = (id) => {
    _log.debug(`Removing ${id} from contacts...`);

    // Get current state values
    const isSignedIn = _isSignedInRef.current;
    const _status = _statusRef.current;
    const _websocket = _websocketRef.current;
    const contacts = _contactsRef.current;

    // Remove contact
    try {
      _public.removeContact({
        id,
        xmppServer,
        isSignedIn,
        _status,
        _websocket,
        contacts,
      });
    } catch (error) {
      _log.error(error);
      throw error;
    }

    _log.debug(`User ${id} removed from contacts list!`);
  };

  const sendFile: CTypes.SDK["sendFile"] = async (id, file) => {
    _log.debug(`Sending file to ${id}...`);

    // Get current state values
    const isSignedIn = _isSignedInRef.current;
    const _status = _statusRef.current;
    const _websocket = _websocketRef.current;
    const currentUser = _currentUserRef.current;
    const contacts = _contactsRef.current;
    const groupChats = _groupChatsRef.current;

    // Send file
    try {
      await _public.sendFile({
        id,
        file,
        xmppServer,
        isSignedIn,
        _status,
        _websocket,
        currentUser,
        contacts,
        groupChats,
      });
    } catch (error) {
      _log.error(error);
      throw error;
    }

    _log.debug("File sent!");
  };

  const sendGroupChatInvite: CTypes.SDK["sendGroupChatInvite"] = async (
    id,
    roomID
  ) => {
    _log.debug(`Sending group chat invite to ${id}...`);

    // Get current state values
    const isSignedIn = _isSignedInRef.current;
    const _status = _statusRef.current;
    const _websocket = _websocketRef.current;

    // Send group chat invite
    try {
      await _public.sendGroupChatInvite({
        id,
        roomID,
        xmppServer,
        isSignedIn,
        _status,
        _websocket,
      });
    } catch (error) {
      _log.error(error);
      throw error;
    }

    _log.debug("Group chat invite sent!");
  };

  const sendInstantMeetingInvite: CTypes.SDK["sendInstantMeetingInvite"] =
    async (id) => {
      _log.debug(`Sending instant meeting invite to ${id}...`);

      // Get current state values
      const currentUser = _currentUserRef.current;
      const isSignedIn = _isSignedInRef.current;
      const _status = _statusRef.current;
      const _websocket = _websocketRef.current;
      const contacts = _contactsRef.current;

      // Send instant meeting invite
      try {
        const instantMeetingInvite = await _public.sendInstantMeetingInvite({
          id,
          xmppServer,
          currentUser,
          isSignedIn,
          _status,
          _websocket,
          contacts,
        });

        // Add to sent invites
        _setSentInstantMeetingInvites((prevState) => [
          ...prevState,
          instantMeetingInvite,
        ]);
      } catch (error) {
        _log.error(error);
        throw error;
      }

      _log.debug(`Sent instant meeting invite to ${id}!`);
    };

  const sendInstantMeetingResponse: CTypes.SDK["sendInstantMeetingResponse"] = (
    meeting_id,
    response
  ) => {
    _log.debug(`Sending instant meeting response for ${meeting_id}...`);

    // Get current state values
    const currentUser = _currentUserRef.current;
    const isSignedIn = _isSignedInRef.current;
    const _status = _statusRef.current;
    const _websocket = _websocketRef.current;
    const receivedInstantMeetingInvites =
      _receivedInstantMeetingInvitesRef.current;

    // Send instant meeting response
    try {
      _public.sendInstantMeetingResponse({
        meeting_id,
        response,
        currentUser,
        isSignedIn,
        _status,
        _websocket,
        xmppServer,
        receivedInstantMeetingInvites,
      });

      _log.debug("Sent instant meeting response!");
    } catch (error) {
      _log.error(error);
      throw error;
    }

    // Join meeting (if applicable)
    if (response === "accept") _joinInstantMeeting(meeting_id);
  };

  const sendTextMessage: CTypes.SDK["sendTextMessage"] = async (
    room_id,
    text
  ) => {
    _log.debug(`Sending text message to ${room_id}...`);

    // Get current state
    const isSignedIn = _isSignedInRef.current;
    const _status = _statusRef.current;
    const _websocket = _websocketRef.current;
    const rooms = _roomsRef.current;
    const currentUser = _currentUserRef.current;

    // Send text message
    try {
      _public.sendTextMessage({
        room_id,
        text,
        xmppServer,
        isSignedIn,
        _status,
        _websocket,
        rooms,
        currentUser,
      });
    } catch (error) {
      _log.error(error);
      throw error;
    }

    _log.debug(`Text message to ${room_id} sent!`);
  };

  const updateCustomStatus: CTypes.SDK["updateCustomStatus"] = async (
    status: string
  ) => {
    _log.debug(`Updating custom status to ${status}...`);

    // Get current state values
    const isSignedIn = _isSignedInRef.current;
    const _status = _statusRef.current;
    const _websocket = _websocketRef.current;
    const currentUser = _currentUserRef.current;

    // Update custom status
    const newCurrentUser = _public.updateCustomStatus({
      status,
      isSignedIn,
      _status,
      _websocket,
      currentUser,
    });

    // Update state
    _setCurrentUser(newCurrentUser);

    _log.debug(`Custom status updated to ${status}!`);
  };

  const updatePresence = (presence: CTypes.Presence) => {
    _log.debug(`Updating presence to ${presence}...`);

    // Get current state values
    const isSignedIn = _isSignedInRef.current;
    const _status = _statusRef.current;
    const _websocket = _websocketRef.current;
    const currentUser = _currentUserRef.current;

    // Update presence
    _public.updatePresence({
      presence,
      isSignedIn,
      _status,
      _websocket,
      currentUser,
    });

    _log.debug("Request to update presence sent!");
  };

  //////////////////////////////////////////////////////////////////////////////
  //  END PUBLIC FUNCTIONS
  //////////////////////////////////////////////////////////////////////////////
  //
  //  LOADING/AUTH PROCESS
  //
  //////////////////////////////////////////////////////////////////////////////

  // Execute load steps
  useEffect(() => {
    _log.debug("_status change detected...");

    switch (_status) {
      case "loadStepOne":
        _log.debug("Starting load step one...");
        _createWebsocket();
        _loadContacts();
        break;

      case "loadStepTwo":
        _log.debug("Starting load step two...");
        _createSUCRooms();
        _connectWebsocket();
        break;

      case "loadStepThree":
        _log.debug("Starting load step three...");
        _loadRoster();
        break;

      case "active":
        _log.debug("Chat is now active! Loading non-essential data...");
        _setIsSignedIn(true);
        // _loadMessageArchive();
        _getInbox();
        _presenceLogin();
        break;

      case "unloadStepOne":
        _log.debug("Starting unload step one...");
        _destroyWebsocket();
        break;

      case "unloadStepTwo":
        _log.debug("Starting unload step two...");
        _clearData();
        break;

      case "inactive":
        _log.debug("Chat status inactive");
        _setIsSignedIn(false);
        break;

      case "restarting":
        _log.debug("Chat status restarting");
        _destroyWebsocket();
        break;

      default:
        _log.warn("Unknown _status");
        break;
    }
  }, [_status]);

  // Detect load step one completion
  useEffect(() => {
    if (
      _status === "loadStepOne" &&
      !!_websocket &&
      (globalAuth.isGuest || Object.keys(contacts).length) &&
      !!currentUser
    ) {
      _log.debug("Load step one complete!");

      _setLoadStepOneComplete(true);
      _setStatus("loadStepTwo");
    }
  }, [_status, _websocket, contacts, currentUser]);

  // Detect load step two completion
  useEffect(() => {
    if (
      _status === "loadStepTwo" &&
      _websocketActive &&
      !!_websocket &&
      (globalAuth.isGuest || Object.keys(rooms).length)
    ) {
      _log.debug("Load step two complete!");

      _setLoadStepTwoComplete(true);
      _setStatus("loadStepThree");
    }
  }, [_status, _websocketActive, rooms]);

  // Detect load step three completion
  useEffect(() => {
    // TODO check for roster
    if (_status === "loadStepThree" && _rosterLoaded) {
      _log.debug("Load step three complete!");

      _setLoadStepThreeComplete(true);
      _setStatus("active");
    }
  }, [_status, _rosterLoaded, groupChats]);

  // Detect unload step one completion
  useEffect(() => {
    if (_status === "unloadStepOne" && !_websocketActive) {
      _log.debug("Unload step one complete!");
      _setStatus("unloadStepTwo");
    }
  }, [_status, _websocketActive]);

  // Detect unload step two completion
  useEffect(() => {
    if (_status === "unloadStepTwo") {
      _log.debug("Unload step two complete!");
      _setStatus("inactive");
    }
  }, [_status]);

  // Detect restarting step completion
  useEffect(() => {
    const handleRestart = async () => {
      if (_status === "restarting" && !_websocket) {
        await _createWebsocket();
        _configureXMPPHooks();
        _connectWebsocket();
        _setStatus("active");
        _log.debug("Handled restart!");
      }
    };

    handleRestart();
  }, [_status, _websocket]);

  // Log in and out implicitly based on globalState isSignedIn
  useEffect(() => {
    _log.debug("globalState isSignedIn change detected...");
    _log.debug(globalAuth.isSignedIn);
    _log.debug(accountUsers);
    _log.debug(_status);

    // Detect whether or not to sign in
    // (Make sure account users have also loaded)
    if (
      (globalAuth.isSignedIn &&
        accountUsers.length > 0 &&
        _status === "inactive") ||
      globalAuth.isGuest
    ) {
      _setStatus("loadStepOne");
    }

    // Detect whether or not to sign out
    if (!globalAuth.isSignedIn && _status === "active") {
      _setStatus("unloadStepOne");
    }
  }, [globalAuth.isSignedIn, accountUsers]);

  //////////////////////////////////////////////////////////////////////////////
  //  END LOADING/AUTH PROCESS
  //////////////////////////////////////////////////////////////////////////////
  //
  //  XMPP WEBSOCKET INTERFACE
  //
  //////////////////////////////////////////////////////////////////////////////

  /** Connect XMPP Agent hooks to corresponding SDK functions */
  const _configureXMPPHooks = () => {
    _log.debug("Configuring XMPP hooks...");

    // Get current state
    const _websocket = _websocketRef.current;

    try {
      // Validate auth
      if (!globalAuth.isSignedIn) {
        throw new APIAuthError("Not configuring hooks.");
      }

      // Ensure XMPP agent exists
      if (!_websocket) {
        throw new SyncError("Can't configure hooks, XMPP agent not created.");
      }
    } catch (error) {
      _log.error(error);
      return;
    }

    // Connect events
    _websocket.on("available", (data) => _onAvailable(data));
    _websocket.on("session:started", () => _onSessionStarted());
    _websocket.on("mam:item", (mam) => _onMamItem(mam));
    _websocket.on("message", (message) => _onMessage(message));
    _websocket.on("message:sent", async (message) => _onMessageSent(message));
    _websocket.on("muc:available", () => _onMucAvailable());
    _websocket.on("muc:invite", (data) => _onMucInvite(data));
    _websocket.on("muc:unavailable", () => _onMucUnavailable());
    _websocket.on("presence", async (data) => _onPresence(data));
    _websocket.on("roster:update", async () => _onRosterUpdate());
    _websocket.on("stanza", async (data) => _onStanza(data));
    _websocket.on("stream:data", async (data) => _onStreamData(data));
    _websocket.on("stream:error", (e) => _onStreamError(e));
    _websocket.on("subscribe", (data) => _onSubscribe(data));
    _websocket.on("*", async (args) => _onWildcard(args));
    _websocket.on("disconnected", async () => _onDisconnected());

    _log.debug("XMPP websocket hooks configured!");
  };

  /** Handler for 'instant-meeting-accept' message */
  const _handleMessageInstantMeetingAccept = (
    message: XMPP.Stanzas.Message
  ) => {
    _log.debug("Handling 'instant-meeting-accept' message...");

    // Get current state values
    const sentInstantMeetingInvites = _sentInstantMeetingInvitesRef.current;

    // Handle instant meeting invite response
    const updatedSentInstantMeetingInvites =
      _xmpp.handleMessageInstantMeetingAccept({
        message,
        sentInstantMeetingInvites,
      });

    // Update states
    _setSentInstantMeetingInvites(updatedSentInstantMeetingInvites);

    // TODO: Join meeting
  };

  /** Handler for 'instant-meeting-invite' message */
  const _handleMessageInstantMeetingInvite = (
    message: XMPP.Stanzas.Message
  ) => {
    _log.debug("Handling 'instant-meeting-invite' message...");

    // Handle instant meeting invite
    const instantMeetingInvite = _xmpp.handleMessageInstantMeetingInvite({
      message,
    });

    // Update state
    _setReceivedInstantMeetingInvites((prevState) => {
      return [...prevState, instantMeetingInvite];
    });
  };

  /** Handler for 'instant-meeting-reject' message */
  const _handleMessageInstantMeetingReject = (
    message: XMPP.Stanzas.Message
  ) => {
    _log.debug("Handling 'instant-meeting-reject' message...");

    // Get current state
    const sentInstantMeetingInvites = _sentInstantMeetingInvitesRef.current;

    // Handle instant meeting response
    const updatedSentInstantMeetingInvites =
      _xmpp.handleMessageInstantMeetingReject({
        message,
        sentInstantMeetingInvites,
      });

    // Update state
    _setSentInstantMeetingInvites(updatedSentInstantMeetingInvites);
  };

  /** Handler for 'chat' stanzas */
  const _handleStanzaChat = (stanza: XMPP.Stanzas.Message) => {
    _log.debug("Handling 'chat' stanza...");

    // Get current state
    const currentUser = _currentUserRef.current;

    // Handle chat stanza
    const message = _xmpp.handleStanzaChat({ stanza, currentUser });
    if (!message) return;

    // Update state
    _addMessageToRoom(message);
  };

  /** Handler for 'groupchat' stanzas */
  const _handleStanzaGroupChat = (stanza: XMPP.Stanzas.Message) => {
    _log.debug("Handling 'groupchat' stanza...");

    // Get current state
    const currentUser = _currentUserRef.current;

    // Handle group chat stanza
    const message = _xmpp.handleStanzaGroupChat({ stanza, currentUser });
    if (!message) return;

    // Update state
    _addMessageToRoom(message);
  };

  /** Handler for 'unavailable' stanza */
  const _handleStanzaUnavailable = (stanza: XMPP.Stanzas.Presence) => {
    _log.debug("Handling 'unavailable' stanza...");

    // Get current state values
    const contacts = _contactsRef.current;
    const groupChats = _groupChatsRef.current;

    // Handle unavailable stanza
    const newStates = _xmpp.handleStanzaUnavailable({
      stanza,
      contacts,
      groupChats,
    });

    // Update state
    _setContacts(newStates.contacts);
    _setGroupChats(newStates.groupChats);
  };

  /** XMPP hook function: 'available' */
  const _onAvailable = (data: XMPP.Stanzas.ReceivedPresence) => {
    _log.debug("Handling XMPP 'available' event..");

    // Get current state
    const currentUser = _currentUserRef.current;
    const contacts = _contactsRef.current;

    // Handle 'available' function
    const newStates = _xmpp.onAvailable({
      data,
      currentUser,
      contacts,
    });

    // Update states
    _setCurrentUser(newStates.currentUser);
    _setContacts(newStates.contacts);
  };

  /** XMPP hook function: 'disconnected' */
  const _onDisconnected = async () => {
    _log.debug("Handling XMPP 'disconnected' event...");

    // Get current state
    const currentUser = _currentUserRef.current;
    const _status = _statusRef.current;
    const _websocket = _websocketRef.current;

    // Handle event
    const loggingOut = _xmpp.onDisconnected({
      currentUser,
      _status,
      _websocket,
    });

    // Update state
    if (loggingOut) {
      _websocket?.connect();
    }
  };

  /** XMPP hook function: 'mam:item'
   * (message archive)
   */
  const _onMamItem = (mam: XMPP.Stanzas.ReceivedMessage) => {
    _log.debug("Handling XMPP 'mam:item' event...");

    // Get current state
    const currentUser = _currentUserRef.current;

    // Get message from mam item
    const message = _xmpp.onMamItem({
      mam,
      currentUser,
    });
    if (!message) return;

    // Update rooms
    _addMessageToRoom(message);
  };

  /** XMPP hook function: 'message'
   * (a message has been received)
   */
  const _onMessage = (message: XMPP.Stanzas.ReceivedMessage) => {
    _log.debug("Handling XMPP 'message' event...");

    switch (message.type) {
      // @ts-ignore
      case "instant-meeting-invite":
        _handleMessageInstantMeetingInvite(message);
        break;

      // @ts-ignore
      case "instant-meeting-accept":
        _handleMessageInstantMeetingAccept(message);
        break;

      // @ts-ignore
      case "instant-meeting-reject":
        _handleMessageInstantMeetingReject(message);
        break;

      default:
        break;
    }
  };

  /** XMPP hook function: 'message:sent'
   * (a message was successfully sent)
   */
  const _onMessageSent = async (rawMessage: XMPP.Stanzas.Message) => {
    _log.debug("Handling XMPP 'message:sent' event...");

    // Get current state
    const currentUser = _currentUserRef.current;

    try {
      // Validate state
      if (!currentUser) {
        throw new UserError(
          "Can't convert sent message without current user info"
        );
      }

      // Convert / validate message
      const message = await _utils.convertMessage(
        rawMessage,
        currentUser.user_id
      );
      if (!message) {
        throw new MessageError(
          "Can't handle sent message, not properly converted."
        );
      }

      // Handle message
      switch (message.type) {
        case "text":
          _addMessageToRoom(message);
          break;

        case "file":
          _addMessageToRoom(message);
          break;

        case "instant-meeting-invite":
          break;

        case "groupchat": // we'll get notified again if it's a MUC
          message.sentStatus = "sent";
          _addMessageToRoom(message);
          break;

        default:
          _addMessageToRoom(message);
          break;
      }
    } catch (error) {
      _log.error(error);
    }
  };

  /** XMPP hook function: 'muc:available' */
  const _onMucAvailable = async () => {
    _log.debug("Handling XMPP 'muc:available' event...");

    // Get current state
    const _websocket = _websocketRef.current;

    await _websocket?.getRoster();
  };

  /** XMPP hook function: 'muc:invite' */
  const _onMucInvite = (data: XMPP.MUCInviteEvent) => {
    _log.debug("Handling XMPP 'muc:invite' event...");

    // TODO
  };

  /** XMPP hook function: 'muc:unavailable' */
  const _onMucUnavailable = async () => {
    _log.debug("Handling XMPP 'muc:unavailable' event...");

    // Get current state
    const _websocket = _websocketRef.current;

    await _websocket?.getRoster();
  };

  /** XMPP hook function: 'presence' */
  const _onPresence = async (data: XMPP.Stanzas.ReceivedPresence) => {
    _log.debug("Handling XMPP 'presence' event...");

    // Get current state
    const currentUser = _currentUserRef.current;
    const contacts = _contactsRef.current;
    const groupChats = _groupChatsRef.current;

    // Handle 'presence' xmpp event
    const newStates = _xmpp.onPresence({
      data,
      currentUser,
      contacts,
      groupChats,
    });

    // Update state values
    _setCurrentUser(newStates.currentUser);
    _setContacts(newStates.contacts);
    _setGroupChats(newStates.groupChats);
  };

  /** XMPP hook function: 'roster:update' */
  const _onRosterUpdate = async () => {
    _log.debug("Handling XMPP 'roster:update' event...");

    _updateRoster();
  };

  /** XMPP hook function: 'session:started'
   * (XMPP agent successfully authenticated)
   */
  const _onSessionStarted = async () => {
    _log.debug("Handling XMPP 'session:started' event...");

    // Get current state
    const _websocket = _websocketRef.current;

    // Handle session:started event
    _xmpp.onSessionStarted({ _websocket });

    // Update state
    _setWebsocketActive(true);
  };

  /** XMPP hook function: 'stanza' */
  const _onStanza = async (
    stanza: XMPP.Stanzas.Presence | XMPP.Stanzas.Message | XMPP.Stanzas.IQ
  ) => {
    _log.debug("Handling XMPP 'stanza' event...");

    // Pass stanza off to handler functions
    switch (stanza.type) {
      case "chat":
        _handleStanzaChat(stanza);
        break;

      // @ts-ignore
      case "groupchat":
        _handleStanzaGroupChat(stanza);
        break;

      case "get":
        break;

      case "unavailable":
        _handleStanzaUnavailable(stanza);
        break;

      default:
        break;
    }
  };

  /** XMPP hook function: 'stream:data' */
  const _onStreamData = async (data: any) => {
    _log.debug("Handling XMPP 'stream:data' event...", data);

    // Get current state values
    const currentUser = _currentUserRef.current;
    const _websocket = _websocketRef.current;
    const rooms = _roomsRef.current;

    // Handle 'stream:data' hook
    const newStates = _xmpp.onStreamData({
      data,
      currentUser,
      _websocket,
      rooms,
    });
  };

  /** XMPP hook function: 'stream:error' */
  const _onStreamError = (e: XMPP.Stanzas.StreamError) => {
    _log.debug("Handling XMPP 'stream:error' event...");

    _log.error(
      new XMPPStreamError(`An XMPP stream error occurred: ${JSON.stringify(e)}`)
    );
  };

  /** XMPP hook function: 'subscribe'
   *  (When another user subscribes to us)
   */
  const _onSubscribe = (data: XMPP.Stanzas.ReceivedPresence) => {
    _log.debug("Handling XMPP 'subscribe' event...");

    // Get current state
    // const _websocket = _websocketRef.current;

    // no longer auto accepting or subscribing back!
    // _websocket?.acceptSubscription(data.from);
    // _websocket?.subscribe(data.from);
  };

  /** XMPP hook wildcard function (only use in development) */
  const _onWildcard = async (...args: any[]) => {
    /*     _log.log("xmpp event", args) */
  };

  /** Update XMPP presence on signin */
  const _presenceLogin = () => {
    // Get current state
    const currentUser = _currentUserRef.current;
    const _websocket = _websocketRef.current;
    _private.presenceLogin({
      currentUser,
      _websocket,
    });
  };

  //////////////////////////////////////////////////////////////////////////////
  //  END XMPP WEBSOCKET INTERFACE
  //////////////////////////////////////////////////////////////////////////////
  //
  //  PRIVATE FUNCTIONS
  //
  //////////////////////////////////////////////////////////////////////////////

  /** Add message to given room */
  const _addMessageToRoom = (message: CTypes.Message, roomID?: string) => {
    _log.debug(`Adding message ${message._id} to room ${roomID}`);

    // Get current state values
    const currentUser = _currentUserRef.current;
    const rooms = _roomsRef.current;

    const newRooms = _private.addMessageToRoom({
      message,
      rooms,
      currentUser,
      roomID,
    });
    _setRooms(newRooms);
  };

  /** Clear out all data */
  const _clearData = () => {
    _log.debug(`Clearing all Chat SDK data...`);

    // Clear XMPP agent / websocket
    _setWebsocket(null);

    // Clear XMPP data
    _setRoster([]);

    // Clear SDK data
    _setCurrentUser(null);
    _setContacts({});
    _setGroupChats({});
    _setRooms({});
    _setReceivedInstantMeetingInvites([]);
    _setSentInstantMeetingInvites([]);

    // Clear loading data
    _setLoadStepOneComplete(false);
    _setLoadStepTwoComplete(false);
    _setLoadStepThreeComplete(false);
    _setUnloadStepOneComplete(false);
    _setUnloadStepTwoComplete(false);
    _setRosterLoaded(false);
  };

  /** Connect XMPP websocket */
  const _connectWebsocket = () => {
    _log.debug("Connecting XMPP websocket to server...");

    // Get current state values
    const _websocket = _websocketRef.current;

    _configureXMPPHooks();

    // Connect websocket
    _private.connectWebsocket({
      _websocket,
    });
  };

  /** Create XMPP websocket */
  const _createWebsocket = async () => {
    _log.debug("Creating XMPP websocket...");

    // Get current state values
    const _websocket = _websocketRef.current;

    // Create websocket
    const newWebsocket = await _private.createWebsocket({
      _websocket,
      xmppServer,
    });
    if (!newWebsocket) {
      throw new XMPPAuthError("Could not create or connect to XMPP websocket");
    }

    // Update state
    _setWebsocket(newWebsocket);
  };

  /** Create single user chat rooms */
  const _createSUCRooms = () => {
    _log.debug("Creating single user chat rooms...");

    // Get current state values
    const contacts = _contactsRef.current;
    const rooms = _roomsRef.current;

    // Create rooms
    const tmpRooms = _private.createSUCRooms({ contacts });

    // Update rooms
    const newState = {
      ...rooms,
      ...tmpRooms,
    };
    _setRooms(newState);
  };

  /** Disconnect from websocket */
  const _destroyWebsocket = () => {
    _log.debug("Destroying websocket...");

    // Get current state values
    const _websocket = _websocketRef.current;

    // Disconnect websocket
    const newWebsocket = _private.destroyWebsocket({ _websocket });

    // Update state
    _setWebsocket(newWebsocket);
    _setWebsocketActive(false);
  };

  /** Join an instant meeting */
  const _joinInstantMeeting = (id: string) => {
    _log.debug(`Joining instant meeting ${id}...`);

    router.push("/meeting/[uuid]", `/meeting/${id}`);
  };

  /** Load initial contacts list and current user */
  const _loadContacts = async () => {
    _log.debug("Loading contacts from user pool...");

    // Get current state values
    const currentUser = _currentUserRef.current;
    const contacts = _contactsRef.current;

    // Load contacts data
    const newStates = await _private.loadContacts({
      xmppServer,
      currentUser,
      contacts,
      accountUsers,
    });
    const guestUser: CTypes.Contact = {
      _jid: `${globalAuth.guestID}@${xmppServer.hostname}`,
      avatar: "",
      contact: false,
      display_name: globalAuth.guestName,
      email: globalAuth.guestID,
      first_name: globalAuth.guestName,
      last_name: "",
      full_name: globalAuth.guestName,
      name: globalAuth.guestName,
      presence: "offline",
      status: "",
      user_id: globalAuth.guestID,
    };

    // Update states
    const curUser = globalAuth.isGuest ? guestUser : newStates.currentUser;

    _setCurrentUser(curUser);
    _setContacts(newStates.contacts);
  };

  /** Load message archive */
  const _loadMessageArchive = async () => {
    _private.getAllMessages({ _websocket });
  };

  /** Get Inbox */
  const _getInbox = async () => {
    const inbox = await _private.getInbox();
    inbox.forEach((m: any) => {
      _addMessageToRoom({
        ...m.message,
        _id: m.msg_id,
        timestamp: new Date(m.timestamp),
        sentStatus: "delivered",
      });
    });
  };

  const getChatHistory = async (id: string) => {
    const room = rooms[id] || {
      room_id: id,
      type: "MUC",
    };
    const history = await _private.getChatHistory(room);
    console.log("history", history);
    history?.forEach?.((m: any) => {
      _addMessageToRoom(
        {
          ...m,
          _id: m.id,
          timestamp: new Date(m.timestamp),
          sentStatus: "delivered",
        },
        id
      );
    });
  };

  /** Load roster data into contacts */
  const _loadRoster = async () => {
    _log.debug("Loading roster...");

    try {
      // Validate websocket
      if (!_websocket) {
        throw new SDKActivationError("Can't load roster, no XMPP agent.");
      }
      if (!_websocketActive) {
        throw new SDKActivationError(
          "Can't load roster, XMPP websocket not connected"
        );
      }
    } catch (error) {
      _log.error(error);
      return;
    }

    _updateRoster();
  };

  /** Update contacts with new roster data */
  const _updateRoster = async () => {
    _log.debug("Updating contacts with roster data...");

    // Get current state
    const _websocket = _websocketRef.current;
    const _rosterLoaded = _rosterLoadedRef.current;
    const contacts = _contactsRef.current;

    // Update roster
    const newStates = await _private.updateRoster({
      _websocket,
      contacts,
    });

    // Update state values
    _setContacts(newStates.contacts);
    _setGroupChats(newStates.groupChats);
    if (!_rosterLoaded) _setRosterLoaded(true);
  };

  //////////////////////////////////////////////////////////////////////////////
  //  END PRIVATE FUNCTIONS
  //////////////////////////////////////////////////////////////////////////////
  //
  //  PROVIDER COMPONENT
  //
  //////////////////////////////////////////////////////////////////////////////

  return (
    <Context.Provider
      value={{
        isSignedIn,
        currentUser,
        contacts,
        groupChats,
        mjwt,
        rooms,
        sentInstantMeetingInvites,
        receivedInstantMeetingInvites,
        addContact,
        createGroupChat,
        createInstantMeeting,
        guestLogin,
        removeContact,
        sendFile,
        sendGroupChatInvite,
        sendInstantMeetingInvite,
        sendInstantMeetingResponse,
        sendTextMessage,
        updateCustomStatus,
        updatePresence,
        getChatHistory,
      }}
    >
      {children}
    </Context.Provider>
  );

  //////////////////////////////////////////////////////////////////////////////
  //  END PROVIDER COMPONENT
  //////////////////////////////////////////////////////////////////////////////
};

export default ChatProvider;
