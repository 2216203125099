/* eslint-disable camelcase */
/* eslint-disable valid-jsdoc */
import * as ChatTypes from "../types";

/** Create a blank SUC room with given room id */
const createSUCRoom = (room_id: string): ChatTypes.SUCRoom => {
  const type = "SUC";
  const messages: ChatTypes.Message[] = [];

  return {
    type,
    room_id,
    messages,
  };
};

export default createSUCRoom;
