import * as XMPP from "stanza";

import { SDKActivationError } from "@chat/_errors";
import * as CTypes from "../types";
import * as _log from "../_log";
import * as _utils from "../_utils";

/**
 * Handle 'stream:data' logic. Returns false if no states change, or new states
 * otherwise.
 */
const onStreamData = ({
  data,
  currentUser,
  _websocket,
  rooms,
}: {
  data: any;
  currentUser: CTypes.SDK["currentUser"];
  _websocket: CTypes.Websocket;
  rooms: CTypes.SDK["rooms"];
}) => {
  try {
    // Validate state
    if (!currentUser) {
      throw new SDKActivationError(
        "Can't handle stream data, no current user info."
      );
    } else if (!_websocket) {
      throw new SDKActivationError("Can't handle stream data, no websocket.");
    }

    // newStates
    const newStates = {
      currentUser: { ...currentUser },
      rooms: { ...rooms },
    };

    // Check if this message was sent by same account from another resource
    if (
      _utils.formatID(data.from) === currentUser.user_id &&
      _utils.formatID(data.to) === currentUser.user_id &&
      data.carbon &&
      Object.keys(data.carbon).length
    ) {
      // Get message
      const message = _utils.convertMessage(
        data.carbon.forward.message,
        currentUser.user_id
      );
    }
  } catch (error) {
    _log.error(error);
  }

  return false;
};

export default onStreamData;
