import * as CTypes from "../types";
import { SyncError } from "../_errors";

const destroyWebsocket = (props: { _websocket: CTypes.Websocket }) => {
  const { _websocket } = { ...props };

  // Validate state
  if (!_websocket) {
    throw new SyncError(
      "Can't disconnect XMPP websocket, websocket doesn't exist."
    );
  }

  // Disconnect
  _websocket.disconnect();

  // Return new state
  return null;
};

export default destroyWebsocket;
