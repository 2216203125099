import { createTheme } from "@mui/material";

// Font list
export const fontList = [
  "Nunito Sans",
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Roboto",
  "Oxygen",
  "Ubuntu",
  "Cantarell",
  "Fira Sans",
  "Droid Sans",
  "Helvetica Neue",
  "sans-serif",
].join(",");

// Theme colors
export const color = {
  primary: "#042349",
  primaryDark: "#031C3A",
  secondary: {
    main: "#1271EE",
    dark: "#0F59BA",
    light: "#E7F1FD",
  },
  info: {
    main: "#FAFAFA",
  },

  tertiary: {
    success: "#01B695",
    warning: "#FDA40E",
    danger: "#FF0000",
  },
  monochrome: {
    black: "#000",
    white: "#FFF",
    grey: "#CCC",
    lightGrey: "#FAFAFA",
  },
};

// Font sizes
export const font = {
  size: {
    sm: "0.875rem", // 14px
    base: "1rem", // 16px
    md: "1.125rem", // 18px
    lg: "1.5rem", // 24px
    xl: "2rem", // 32px
    xxl: "2.5rem", // 40px
  },
};

const theme = createTheme({
  palette: {
    text: {
      primary: color.monochrome.black,
    },
    primary: {
      main: color.primary,
      dark: color.primaryDark,
    },
    secondary: {
      main: color.secondary.main,
      dark: color.secondary.dark,
    },
    success: {
      main: color.tertiary.success,
    },
    warning: {
      main: color.tertiary.warning,
    },
    error: {
      main: color.tertiary.danger,
    },
    info: {
      main: color.info.main,
    },
    grey: {
      500: color.monochrome.grey,
    },
    background: {
      default: color.monochrome.lightGrey,
      paper: color.monochrome.white,
    },

    // info: {}
  },

  typography: {
    fontFamily: fontList,
    allVariants: {
      color: color.primary,
    },
    h1: {
      fontSize: font.size.xxl,
      fontWeight: "bold",
    },
    h2: {
      fontSize: font.size.xl,
      fontWeight: "bold",
    },
    h3: {
      fontSize: font.size.lg,
      fontWeight: "bold",
    },
    h4: undefined,
    h5: undefined,
    h6: undefined,
    body1: {
      fontSize: font.size.base,
    },
    caption: {
      // design system calls it "label"
      fontSize: font.size.sm,
    },
  },
  components: {
    /** Buttons */
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: font.size.md,
          fontWeight: "bold",
          textTransform: "none",
          borderRadius: "4px",
          border: "1px solid transparent",
          "&:hover": {
            border: "1px solid transparent",
          },

          "&.Mui-disabled": {
            color: color.monochrome.white,
            background: color.monochrome.grey,
            borderColor: color.monochrome.grey,
          },
          "&.Mui-disabled:hover": {
            background: color.monochrome.grey,
            borderColor: color.monochrome.grey,
          },
          "&.Mui-disabled.MuiButton-text": {
            background: "transparent",
            borderColor: "transparent",
            color: color.monochrome.grey,
          },
          "&.Mui-disabled.MuiButton-text:hover": {
            background: "transparent",
            borderColor: "transparent",
            color: color.monochrome.grey,
          },
        },
        sizeLarge: {
          height: "50px",
          fontSize: font.size.md,
          padding: "8px 24px",
        },
        sizeSmall: {
          height: "40px",
          fontSize: font.size.base,
          padding: "8px 18px",
        },
      },
      variants: [
        {
          /** Button Contained */
          props: { variant: "contained" },
          style: {},
        },
        {
          /** Button Outlined */
          props: { variant: "outlined" },
          style: {
            border: "1px solid",
            "&:hover": {
              border: "1px solid",
            },
          },
        },
      ],
    },
    /** TextInput */
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        root: {
          background: color.monochrome.white,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: color.monochrome.grey,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: color.monochrome.grey,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: color.secondary.main,
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: color.tertiary.danger,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderWidth: "2px",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: false,
      },
      styleOverrides: {
        root: {
          position: "static",
          transform: "none",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 48,
          height: 27,
          padding: 0,
          "& .MuiSwitch-root": {
            paddingLeft: 10,
          },

          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(18px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                backgroundColor: color.secondary.main,
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: "#33cf4d",
              border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color: "rgba(204, 204, 204, 1)",
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.7,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 22,
            height: 22,
          },
          "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: "#E9E9EA",
            opacity: 1,
            transition: {
              duration: 500,
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: color.secondary.main,
          "&.Mui-checked": {
            color: color.secondary.main,
          },
        },
      },
    },
  },
});

export default theme;
