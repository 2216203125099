import * as CTypes from "../types";
import * as _utils from ".";

/**
 * Convert XMPP MUC data into SDK groupChat
 *
 * @param {string} jid - MUC JID
 * @param {string[]} users
 * @param {string | undefined} name - MUC room name (default = "untitled")
 * @param {string | undefined} avatar
 *
 * @return {CTypes.GroupChat}
 */
const formatGroupChat = (
  jid: string,
  users: string[],
  name?: string,
  avatar?: string
): CTypes.GroupChat => {
  const _jid = jid;
  const id = _utils.formatID(jid);
  const presence = "available";
  const roomName = name || "Untitled";

  return {
    _jid,
    id,
    name: roomName,
    users,
    presence,
    avatar,
  };
};

export default formatGroupChat;
