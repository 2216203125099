import { SDKActivationError, UserError } from "@chat/_errors";
import * as CTypes from "../types";
import * as _utils from "../_utils";
import * as _api from "../_api";
import { getServiceUrls } from "@utilities";

const removeContact = async ({
  id,
  xmppServer,
  isSignedIn,
  _status,
  _websocket,
  contacts,
}: {
  id: string;
  xmppServer: CTypes.XMPPServerData;
  isSignedIn: CTypes.SDK["isSignedIn"];
  _status: CTypes.LoadStatus;
  _websocket: CTypes.Websocket;
  contacts: CTypes.ContactList;
}) => {
  // Validate state
  if (!isSignedIn || _status !== "active" || !_websocket) {
    throw new SDKActivationError("Can't remove contact, not signed in.");
  }

  // Ensure user exists and is a contact
  if (!contacts[id]) {
    throw new UserError("Can't remove contact, user id not found.");
  }
  if (!contacts[id].contact) {
    throw new UserError("Can't remove contact, user is not a contact");
  }

  const { api } = getServiceUrls();
  const url = `${api}/api/users/contacts`;
  const { jwt } = await _api.getUserCredentials(xmppServer);
  const init = {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: jwt,
    },
    body: JSON.stringify({
      contacts: [id],
    }),
  };

  const res = await fetch(url, init);
  console.log(res);

  // Remove contact
  // _websocket.removeRosterItem(_utils.formatJID(id, xmppServer));
};

export default removeContact;
