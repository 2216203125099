import * as CTypes from "../types";
import * as Types from "types";
import * as _api from "../_api";
import * as _utils from "../_utils";
import { SyncError } from "../_errors";

const loadContacts = async (props: {
  xmppServer: CTypes.XMPPServerData;
  currentUser: CTypes.SDK["currentUser"];
  contacts: CTypes.ContactList;
  accountUsers: Types.OtherUsers[];
}) => {
  const { xmppServer, currentUser, contacts, accountUsers } = { ...props };

  // Validate state
  if (Object.keys(contacts).length >= 1) {
    throw new SyncError("Can't load contacts, contacts already loaded.");
  }
  if (currentUser) {
    throw new SyncError("Can't load contacts, there is already a currentUser");
  }

  let newCurrentUser: CTypes.Contact | null = null;
  const newContacts: CTypes.ContactList = {};

  // Create currentUser and contacts list
  const me = await _api.getUserCredentials(xmppServer);
  accountUsers.forEach((tmpUser) => {
    // Load current user
    if (tmpUser.user_id === me.user_id) {
      newCurrentUser = _utils.formatContact(tmpUser, xmppServer);
    }

    // Load other account user
    else {
      newContacts[tmpUser.user_id] = _utils.formatContact(tmpUser, xmppServer);
    }
  });

  // Return new states
  return {
    currentUser: newCurrentUser,
    contacts: newContacts,
  };
};

export default loadContacts;
