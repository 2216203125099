/* eslint-disable camelcase */
/* eslint-disable valid-jsdoc */
import * as XMPP from "stanza";

import * as _utils from "../_utils";
import * as _log from "../_log";
import * as ChatTypes from "../types";
import { MessageError } from "../_errors";

/**
 * Convert XMPP message to Chat SDK message
 *
 * @param {XMPP.Stanzas.Message} rawMessage the xmpp message
 * @param {string} curUserID current user's ID
 * @param {Date} timestamp timestamp of message
 *
 * @return {ChatTypes.Messag | false} converted message or nothing
 */
const convertMessage = (
  rawMessage: XMPP.Stanzas.Message,
  curUserID: string,
  timestamp?: Date
): ChatTypes.Message | false => {
  try {
    // Validate message structure
    if (
      !rawMessage ||
      !rawMessage.id ||
      !rawMessage.type ||
      !rawMessage.body ||
      !(rawMessage.to || rawMessage.from)
    ) {
      console.log(rawMessage);
      // throw new MessageError("Message has invalid structure");
      // until we catch this error, can we just return here? My console has a lot of red
      return false;
    }
  } catch (error) {
    _log.error(error);
    return false;
  }

  const from = rawMessage.from ? _utils.formatID(rawMessage.from) : curUserID;
  const to = rawMessage.to ? _utils.formatID(rawMessage.to) : curUserID;
  let participant;

  if (rawMessage.type === "groupchat" && rawMessage.from) {
    participant = _utils.formatID(rawMessage.from?.split("/")?.[1]);
  }

  // Create message attributes
  const message: ChatTypes.Message = {
    _id: rawMessage.id,
    from,
    to,
    timestamp: timestamp || new Date(),
    type: rawMessage.type,
    body: rawMessage.body,
    sentStatus: "delivered",
    participant,
  };

  return message;
};

export default convertMessage;
