import * as CTypes from "../types";
import * as _api from "../_api";

const createInstantMeeting = async ({
  isSignedIn,
  currentUser,
}: {
  isSignedIn: CTypes.SDK["isSignedIn"];
  currentUser: CTypes.SDK["currentUser"];
}) => {
  let meetingId = "";

  // Validate state
  if (!isSignedIn || !currentUser) return meetingId;

  // Create instant meeting
  const meeting = await _api.createInstantMeeting();
  meetingId = meeting.meeting_id;
  return meetingId;
};

export default createInstantMeeting;
