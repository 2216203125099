import * as CTypes from "../types";
import * as _api from "../_api";
import * as _log from "../_log";

const guestLogin = async ({
  meetingId,
  name,
  _status,
}: {
  /** ID of meeting for guest to join */
  meetingId: string;

  /** Guest's name */
  name: string;

  _status: CTypes.LoadStatus;
}) => {
  // Validate state
  if (_status !== "inactive") {
    _log.warn("Status not inactive, this might not work as intended...");
  }

  try {
    // Get mjwt
    const auth = await _api.authorizeMeetingGuest({ meetingId, name });
    if (!auth) {
      throw new Error("Couldn't get mjwt for guest login");
    }
    const data = JSON.parse(
      Buffer.from(auth.split(".")[1], "base64").toString()
    );
    console.log(data);
    return data;
  } catch (error) {
    _log.error(error);
    return null;
  }
};

export default guestLogin;
