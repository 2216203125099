/** Errors related to XMPP messages */
class MessageError extends Error {
  constructor(message: string) {
    super(message);

    this.name = "MessageError";
  }
}

export default MessageError;
