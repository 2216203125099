import * as XMPP from "stanza";
import * as CTypes from "../types";
import * as _utils from "../_utils";
import * as _log from "../_log";
import { MessageError, DataError } from "../_errors";

const handleStanzaUnavailable = ({
  stanza,
  contacts,
  groupChats,
}: {
  stanza: XMPP.Stanzas.Presence;
  contacts: CTypes.SDK["contacts"];
  groupChats: CTypes.SDK["groupChats"];
}) => {
  // Validate message
  if (!stanza.from) {
    throw new MessageError("Can't uupdate presence without from field.");
  }

  // Get room ID
  const from = _utils.formatID(stanza.from);

  // New updated states
  const newContacts: CTypes.SDK["contacts"] = { ...contacts };
  const newGroupChats: CTypes.SDK["groupChats"] = { ...groupChats };

  // Create updated contacts list (if applicable)
  if (Object.keys(newContacts).includes(from)) {
    newContacts[from].presence = "offline";
  }

  // Create updated group chats list (if applicable)
  else if (Object.keys(newGroupChats).includes(from)) {
    newGroupChats[from].presence = "unavailable";
  }

  // If ID is not in contacts or group chats (this should never happen)
  else {
    _log.warn(
      `Can't handle presence update for ${from}, this may be an invalid id.`
    );
  }

  // Return new states
  return {
    contacts: newContacts,
    groupChats: newGroupChats,
  };

  // TODO
};

export default handleStanzaUnavailable;
