import * as CTypes from "../types";
import * as _utils from "../_utils";
import * as _api from "../_api";
import { DataError, SDKActivationError, UserError } from "@chat/_errors";

const sendInstantMeetingInvite = async ({
  id,
  xmppServer,
  currentUser,
  isSignedIn,
  _status,
  _websocket,
  contacts,
}: {
  id: string;
  xmppServer: CTypes.XMPPServerData;
  currentUser: CTypes.Contact | null;
  isSignedIn: CTypes.SDK["isSignedIn"];
  _status: CTypes.LoadStatus;
  _websocket: CTypes.Websocket;
  contacts: CTypes.ContactList;
}) => {
  // Validate state
  if (!isSignedIn || _status !== "active" || !_websocket) {
    throw new SDKActivationError(
      "Can't send instant meeting invite, not signed in."
    );
  }
  if (!currentUser) {
    throw new DataError(
      "Can't send instant meeting invite, no current user data."
    );
  }

  // Validate message recipient
  if (!contacts[id]) {
    throw new UserError("Can't send message, user not in contacts.");
  }

  // Create instant meeting
  const meeting = await _api.createInstantMeeting(id);

  // Invite / response timer
  const sendTime = new Date();
  const maximumResponseTime = new Date(sendTime.toString()).setSeconds(
    sendTime.getSeconds() + 30
  );

  // Create instant meeting invite
  const instantMeetingInvite: CTypes.SentInstantMeetingInvite = {
    meeting_id: meeting.meeting_id,
    meeting,
    invitee: id,
    send_time: sendTime.getTime(),
    maximum_response_time: maximumResponseTime,
  };

  // Create XMPP meeting invite body
  const instantInviteBody = {
    meeting_id: instantMeetingInvite.meeting_id,
    maximum_response_time: instantMeetingInvite.maximum_response_time,
  };

  // Send invite
  _websocket.sendMessage({
    to: _utils.formatJID(id, xmppServer),
    from: currentUser._jid,
    body: JSON.stringify(instantInviteBody),
    // @ts-ignore
    type: "instant-meeting-invite",
  });

  // Return instant meeting invite
  return instantMeetingInvite;
};

export default sendInstantMeetingInvite;
