/* eslint-disable camelcase */
/* eslint-disable valid-jsdoc */
import * as ChatTypes from "../types";
import * as Types from "../../types";
import formatJID from "./formatJID";

/** Convert cognito user to Chat SDK Contact */
const formatContact = (
  user: Types.OtherUsers,
  xmppServer: ChatTypes.XMPPServerData
): ChatTypes.Contact => {
  const full_name = `${user.first_name} ${user.last_name}`;
  return {
    _jid: formatJID(user.user_id, xmppServer),
    user_id: user.user_id,
    email: user.email,
    first_name: user.first_name || "",
    last_name: user.last_name || "",
    full_name,
    display_name: user.display_name || full_name,
    name:
      user.display_name ||
      user.first_name ||
      `${user.first_name} ${user.last_name}` ||
      "[No Name]",
    avatar: user.picture || "",
    contact: false,
    presence: "offline",
    status: "",
  };
};

export default formatContact;
