import { SDKActivationError } from "@chat/_errors";
import * as CTypes from "../types";
import * as _utils from "../_utils";

const sendGroupChatInvite = ({
  id,
  roomID,
  xmppServer,
  isSignedIn,
  _status,
  _websocket,
}: {
  id: string;
  roomID: string;
  xmppServer: CTypes.XMPPServerData;
  isSignedIn: CTypes.SDK["isSignedIn"];
  _status: CTypes.LoadStatus;
  _websocket: CTypes.Websocket;
}) => {
  // Validate state
  if (!isSignedIn || _status !== "active" || !_websocket) {
    throw new SDKActivationError("Can't send group invite, not signed in.");
  }

  // Get JIDs
  const userJID = _utils.formatJID(id, xmppServer);
  const roomJID = _utils.formatJID(roomID, xmppServer, true);

  // Send invite
  _websocket.setRoomAffiliation(roomJID, userJID, "member");
};

export default sendGroupChatInvite;
