import * as CTypes from "../types";

const presenceLogin = ({
  currentUser,
  _websocket,
}: {
  currentUser: CTypes.SDK["currentUser"];
  _websocket: CTypes.Websocket;
}) => {
  if (currentUser?.presence === "offline") {
    _websocket?.sendPresence({ status: "available" });
  }
};

export default presenceLogin;
