import * as CTypes from "../types";
import * as _log from "../_log";
import { SDKActivationError, DataError } from "@chat/_errors";

const updatePresence = ({
  presence,
  isSignedIn,
  _status,
  _websocket,
  currentUser,
}: {
  presence: CTypes.Presence;
  isSignedIn: CTypes.SDK["isSignedIn"];
  _status: CTypes.LoadStatus;
  _websocket: CTypes.Websocket;
  currentUser: CTypes.SDK["currentUser"];
}) => {
  try {
    // Validate state
    if (!isSignedIn || _status !== "active" || !_websocket) {
      throw new SDKActivationError("Can't update presence, not signed in.");
    }
    if (!currentUser) {
      throw new DataError("Can't update presence, no current user info");
    }
  } catch (error) {
    _log.error(error);
    return;
  }

  // Update presence
  // @ts-ignore
  _websocket.sendPresence({ status: presence });
};

export default updatePresence;
