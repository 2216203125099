import * as XMPP from "stanza";

import * as ChatTypes from "../types";
import * as _log from "../_log";
import * as _api from "../_api";
import { APIAuthError } from "../_errors";
import { getCurrentUserInfo } from "@cognito";
import { authStore } from "@state/store";

/**
 * Creates and returns new XMPP client
 *
 * @param {ChatTypes.XMPPServerData} xmppServer - XMPP server config object
 *
 * @return {XMPP.Agent} - XMPP client
 */
const getXMPPClient = async (xmppServer: ChatTypes.XMPPServerData) => {
  const { isGuest } = authStore.getState();

  try {
    const credentials = await _api.getUserCredentials(xmppServer);
    if (!credentials) {
      throw new APIAuthError("Could not initialize XMPP without credentials");
    }

    let resource = +new Date() + "";
    if (!isGuest) {
      // get the deviceKey from Amplify
      const user = await getCurrentUserInfo();
      // the Amplify types must not have these....
      // @ts-ignore: Unreachable code error
      user?.getCachedDeviceKeyAndPassword();
      // @ts-ignore: Unreachable code error
      resource = user?.deviceKey || "";
    }

    const websocket = `${xmppServer.protocol}://${xmppServer.hostname}:${xmppServer.port}/${xmppServer.endpoint}`;

    return XMPP.createClient({
      jid: credentials.jid,
      password: credentials.jwt,
      resource,
      transports: {
        websocket,
      },
    });
  } catch (error) {
    _log.error(error);
    return null;
  }
};

export default getXMPPClient;
