/** Errors related to API servers (any API that is not XMPP) */
class APIServerError extends Error {
  constructor(message: string) {
    const errorMessage = message;
    super(errorMessage);

    this.name = "APIServerError";
  }
}

export default APIServerError;
