import * as XMPP from "stanza";

import * as CTypes from "../types";
import * as _log from "../_log";
import { DataError, SDKActivationError, SyncError } from "../_errors";

const updateCustomStatus = ({
  status,
  isSignedIn,
  _status,
  _websocket,
  currentUser,
}: {
  status: string;
  isSignedIn: CTypes.SDK["isSignedIn"];
  _status: CTypes.LoadStatus;
  _websocket: CTypes.Websocket;
  currentUser: CTypes.SDK["currentUser"];
}) => {
  try {
    // Validate state
    if (!isSignedIn || _status !== "active" || !_websocket) {
      throw new SDKActivationError(
        "Can't update custom status, not signed in."
      );
    }
    if (!currentUser) {
      throw new DataError("Can't update custom status, no current user info.");
    }

    // Update custom status
    _websocket.publishActivity({ activity: [status] });
  } catch (error) {
    _log.error(error);
  }

  // Return new current user
  return currentUser;
};

export default updateCustomStatus;
