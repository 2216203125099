import * as ChatTypes from "../types";

/**
 * Format user / group chat ID to JID
 *
 * @param {string} id - usser or group chat ID
 * @param {ChatTypes.XMPPServerData} xmppServer - XMPP server config object
 * @param {boolean | undefined} muc - Is this a group chat id?
 *
 * @return {string} jid
 */
const formatJID = (
  id: ChatTypes.ID,
  xmppServer: ChatTypes.XMPPServerData,
  muc?: boolean
): ChatTypes.JID => {
  let newJID = id.replace(/\/.*$/, "");
  if (newJID.split("@").length === 1) {
    newJID = `${newJID}@${muc ? xmppServer.mucHostname : xmppServer.hostname}`;
  }

  return newJID;
};

export default formatJID;
