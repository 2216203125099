/* eslint-disable camelcase */
/* eslint-disable valid-jsdoc */
import * as XMPP from "stanza";

import * as _log from "../_log";

/** Upload file through xmpp. Returns download url */
const uploadFile = (file: File, xmpp: XMPP.Agent): Promise<string> => {
  _log.debug("Uploading file through xmpp websocket...");

  return new Promise(async (resolve, reject) => {
    // Get file info
    const { name, size, type: mediaType } = file;

    // Prepare xmpp upload
    const uploadService = await xmpp.getUploadService();
    const uploadSlot = await xmpp.getUploadSlot(uploadService.jid, {
      name,
      size,
      mediaType,
    });
    const {
      download: downloadUrl,
      upload: { url: uploadUrl },
    } = uploadSlot;

    // Attempt file upload
    try {
      await fetch(uploadUrl, {
        method: "PUT",
        body: file,
        headers: { "x-amz-acl": "public-read" },
      });

      resolve(downloadUrl);
    } catch (error) {
      _log.error(error);
      reject(error);
    }
  });
};

export default uploadFile;
