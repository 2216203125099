import * as XMPP from "stanza";
import * as CTypes from "../types";
import * as _utils from "../_utils";
import * as _log from "../_log";
import { DataError, MessageError } from "../_errors";

const onPresence = ({
  data,
  currentUser,
  contacts,
  groupChats,
}: {
  data: XMPP.Stanzas.ReceivedPresence;
  currentUser: CTypes.SDK["currentUser"];
  contacts: CTypes.SDK["contacts"];
  groupChats: CTypes.SDK["groupChats"];
}) => {
  // Validate state
  if (!currentUser) {
    throw new DataError("Can't handle presence update, no current user info.");
  }

  // New state values
  const newStates = {
    currentUser: { ...currentUser },
    contacts: { ...contacts },
    groupChats: { ...groupChats },
  };

  try {
    // Validate xmpp data
    if (!data.status || !data.from || !data.to) {
      throw new MessageError(
        "Received presence update with invalid xmpp message structure."
      );
    }

    // Parse xmpp data
    const id = _utils.formatID(data.from);
    const presence = data.status;

    // If current user's presence update
    if (newStates.currentUser.user_id === id) {
      // @ts-ignore
      newStates.currentUser.presence = presence;
    }

    // If presence update is from a contact
    else if (Object.keys(newStates.contacts).includes(id)) {
      // @ts-ignore
      newStates.contacts[id].presence = presence;
    }

    // If presence update is from a group chat
    else if (Object.keys(newStates.groupChats).includes(id)) {
      // @ts-ignore
      newStates.groupChats[id].presence = presence;
    }

    // If presence update id is not found (this should never run!)
    else {
      throw new MessageError(`Received presence update id ${id} not found!`);
    }
  } catch (error) {
    // Return unchanged values if invalid presence structure
    _log.warn("Received invalid presence update message...");
  }

  return newStates;
};

export default onPresence;
