import { Auth } from "aws-amplify";
import * as Sentry from "@sentry/nextjs";
import { userStore, authStore } from "@state/store";
import { CognitoUser } from "types";

// SETTINGS OBJECT CONTROLING LEVEL OF ERROR REPORTING
const settings = {
  ENVIRONMENT: process.env.NODE_ENV,
  DEBUG: true,
  SENTRY: true,
};

////////////////////////////////////////////////////////////////
//                   GET SECTION
////////////////////////////////////////////////////////////////

/**
 * Request a list of all upcoming meetings
 * @return Response or error message from server
 */

export type VTSignUp = (
  username: string,
  password: string,
  attributes?: any
) => object;

export const signUp: VTSignUp = (username, password, attributes) =>
  new Promise(async function (resolve, reject) {
    try {
      const { user } = await Auth.signUp({
        username: username,
        password: password,
        attributes: {
          given_name: attributes.given_name,
          family_name: attributes.family_name,
          name: attributes.given_name + " " + attributes.family_name,
          updated_at: Date.now().toString(),
        },
      });

      resolve(user);
    } catch (error) {
      if (settings.DEBUG && settings.ENVIRONMENT === "development") {
        console.error(error);
      }
      reject(error);
    }
  });

export type VTConfirmSignUp = (
  username: string,
  verificationCode: string
) => any;

/** verifies the code sent to the user */
export const confirmSignUp: VTConfirmSignUp = (username, verificationCode) => {
  return new Promise(async function (resolve, reject) {
    try {
      const success = await Auth.confirmSignUp(username, verificationCode);
      resolve(success);
    } catch (error) {
      if (settings.DEBUG && settings.ENVIRONMENT === "development") {
        console.error(error);
      }
      reject(error);
    }
  });
};

export type VTResendSignUp = (username: string) => any;

export const resendSignUp: VTResendSignUp = (username) => {
  return new Promise(async function (resolve, reject) {
    try {
      const success = await Auth.resendSignUp(username);
      resolve(success);
    } catch (error) {
      if (settings.DEBUG && settings.ENVIRONMENT === "development") {
        console.error(error);
      }
      reject(error);
    }
  });
};

export type VTSignIn = (username: string, password: string) => any;

export const signIn: VTSignIn = (username, password) =>
  new Promise(async function (resolve, reject) {
    try {
      // Log into cognito
      const user = await Auth.signIn({
        username,
        password,
      });

      // Set new password (if applicable)
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        const { requiredAtributes } = user.challengeParam;
        await Auth.completeNewPassword(user, "password", requiredAtributes);
      }

      if (!user) {
        await Auth.signOut();
      }

      resolve(user);
    } catch (error) {
      if (settings.DEBUG && settings.ENVIRONMENT === "development") {
        console.error(error);
      }
      reject(error);
    }
  });

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    if (settings.DEBUG && settings.ENVIRONMENT === "development") {
      console.error(error);
    }
  }
};

export type VTForgotPassword = (username: string) => any;

// Will send a confirmation code to the user's email
export const forgotPassword: VTForgotPassword = (username: string) =>
  new Promise(async function (resolve, reject) {
    try {
      const success = await Auth.forgotPassword(username);
      console.log("res", success);
      console.log("A code has been sent to email address");
      resolve(success);
    } catch (error) {
      if (settings.DEBUG && settings.ENVIRONMENT === "development") {
        console.error(error);
      }
      reject(error);
    }
  });

export type VTForgotPasswordSubmit = (
  username: string,
  code: string,
  new_password: string
) => any;

/** Collect confirmation code and new password and confirm the change */
export const forgotPasswordSubmit = (
  username: string,
  code: string,
  new_password: string
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const success = await Auth.forgotPasswordSubmit(
        username,
        code,
        new_password
      );
      resolve(success);
    } catch (error) {
      if (settings.DEBUG && settings.ENVIRONMENT === "development") {
        console.error(error);
      }
      reject(error);
    }
  });
};

export type VTVerifyCodeEmail = (attr: string) => any;

/** Confirm the email to send the code to for account recovery if current user is identified */
export const verifyCodeEmail: VTVerifyCodeEmail = (attr: string) => {
  new Promise(async function (resolve, reject) {
    try {
      const success = await Auth.verifyCurrentUserAttribute(attr);
      console.log("A verificiation code was sent");
      resolve(success);
    } catch (error) {
      if (settings.DEBUG && settings.ENVIRONMENT === "development") {
        console.error(error);
      }
      reject(error);
    }
  });
};

export type VTVerifyCodeSubmitted = (attr: string, code: string) => any;

/** Verify that the code is correct only if user is identified */
export const verifyCodeSubmitted: VTVerifyCodeSubmitted = (
  attr: string,
  code: string
) => {
  new Promise(async function (resolve, reject) {
    try {
      const success = await Auth.verifyCurrentUserAttributeSubmit(attr, code);
      console.log("Email has been verified");
      resolve(success);
    } catch (error) {
      if (settings.DEBUG && settings.ENVIRONMENT === "development") {
        console.error(error, "FROM verifyCodeSubmitted");
      }
      reject(error);
    }
  });
};

export type VTGetCurrentUserInfo = () => Promise<CognitoUser>;

/** Get current users attributes */
export const getCurrentUserInfo: VTGetCurrentUserInfo = () => {
  return new Promise(async function (resolve, reject) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (!user) {
        throw new Error("not authenticated");
      }
      resolve(user);
    } catch (error) {
      if (settings.DEBUG && settings.ENVIRONMENT === "development") {
        console.log(error, "FROM getCurrentUserInfo()");
      }
      reject(error);
    }
  });
};

export type VTGetUserSession = () => any;

export const getUserSession: VTGetUserSession = () => {
  return new Promise(async function (resolve, reject) {
    try {
      const user = await Auth.currentSession();
      resolve(user);
    } catch (error) {
      if (settings.DEBUG && settings.ENVIRONMENT === "development") {
        console.error(error);
      }
      reject(error);
    }
  });
};

export type VTUpdateUserAttributes = (user: object, attribute: object) => void;

/** update the user's attributes with a new key, value pair. */
export const updateUserAttributes: VTUpdateUserAttributes = (
  user,
  attribute
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const result = await Auth.updateUserAttributes(user, attribute);
      resolve(result);
    } catch (error) {
      if (settings.DEBUG && settings.ENVIRONMENT === "development") {
        console.error(error);
      }
      reject(error);
    }
  });
};
