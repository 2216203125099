import * as XMPP from "stanza";
import { authStore } from "@state/store";

import { DataError, RoomError, SDKActivationError } from "@chat/_errors";
import * as CTypes from "../types";
import * as _utils from "../_utils";

const sendTextMessage = ({
  room_id,
  text,
  xmppServer,
  isSignedIn,
  _status,
  _websocket,
  rooms,
  currentUser,
}: {
  room_id: string;
  text: string;
  xmppServer: CTypes.XMPPServerData;
  isSignedIn: CTypes.SDK["isSignedIn"];
  _status: CTypes.LoadStatus;
  _websocket: CTypes.Websocket;
  rooms: CTypes.RoomList;
  currentUser: CTypes.Contact | null;
}) => {
  const { isGuest } = authStore.getState();

  // Validate state
  if (!isSignedIn || _status !== "active" || !_websocket) {
    throw new SDKActivationError("Can't send text message, not signed in.");
  }
  if (!currentUser) {
    throw new DataError("Can't send text message, no current user info.");
  }

  // Ensure room exists
  // if (!Object.keys(rooms[room_id]).length) {
  //   throw new RoomError("Can't send text message, room not found.");
  // }

  // Determine message fields (MUC or SUC?)
  let messageBody: XMPP.Stanzas.Message;
  if (rooms[room_id]?.type === "SUC") {
    messageBody = {
      to: _utils.formatJID(room_id, xmppServer),
      from: currentUser._jid,
      body: text,
      type: "chat",
    };
  } else {
    messageBody = {
      to: _utils.formatJID(room_id, xmppServer, true),
      from: currentUser._jid,
      body: text,
      // @ts-ignore
      type: "groupchat",
    };
  }

  // Send text message
  _websocket.sendMessage(messageBody);
};

export default sendTextMessage;
