/** Errors related to missing SDK data */
class DataError extends Error {
  constructor(message: string) {
    const errorMessage = `${message} Missing or invalid data`;
    super(errorMessage);

    this.name = "DataError";
  }
}

export default DataError;
