import { DataError, SDKActivationError } from "@chat/_errors";
import * as CTypes from "../types";
import * as _utils from "../_utils";

const sendFile = async ({
  id,
  file,
  xmppServer,
  isSignedIn,
  _status,
  _websocket,
  currentUser,
  contacts,
  groupChats,
}: {
  id: string;
  file: File;
  xmppServer: CTypes.XMPPServerData;
  isSignedIn: CTypes.SDK["isSignedIn"];
  _status: CTypes.LoadStatus;
  _websocket: CTypes.Websocket;
  currentUser: CTypes.Contact | null;
  contacts: CTypes.ContactList;
  groupChats: CTypes.GroupChatList;
}) => {
  // Validate state
  if (!isSignedIn || _status !== "active" || !_websocket) {
    throw new SDKActivationError("Can't send file, not signed in.");
  }
  if (!currentUser) {
    throw new DataError("Can't send file, no current user info.");
  }

  // Check for id type (muc/suc) and validate id
  let messageType: "chat" | "groupchat" | null = null;
  if (Object.keys(contacts).includes(id)) {
    messageType = "chat";
  } else if (Object.keys(groupChats).includes(id)) {
    messageType = "groupchat";
  } else {
    throw new DataError("Can't send file, invalid id.");
  }

  // Upload image
  const imgDownloadUrl = await _utils.uploadFile(file, _websocket);

  // Send message
  _websocket.sendMessage({
    to: _utils.formatJID(id, xmppServer),
    body: imgDownloadUrl,
  });
};

export default sendFile;
