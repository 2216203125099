import * as XMPP from "stanza";
import * as _utils from "../_utils";
import * as CTypes from "../types";
import { DataError, MessageError } from "../_errors";

const handleMessageInstantMeetingReject = ({
  message,
  sentInstantMeetingInvites,
}: {
  message: XMPP.Stanzas.Message;
  sentInstantMeetingInvites: CTypes.SDK["sentInstantMeetingInvites"];
}) => {
  // Validate XMPP message
  if (!message.body) {
    throw new DataError(
      "Can't handle instant meeting invite response, no message body."
    );
  }

  // Parse response
  const instantMeetingResponse: CTypes.InstantMeetingResponse = JSON.parse(
    message.body
  );

  // Find the original update
  const instantMeetingInvite = sentInstantMeetingInvites.filter(
    (tmpMeeting) => tmpMeeting.meeting_id === instantMeetingResponse.meeting_id
  )[0];
  if (!instantMeetingInvite) {
    throw new DataError(
      "Can't handle instant meeting invite response, meeting id not found!"
    );
  }

  // Update instant invite fields
  instantMeetingInvite.response = "reject";

  // Create new instant invite array
  const updatedInvites = _utils.updateInstantMeetingInvites({
    invite: instantMeetingInvite,
    invites: sentInstantMeetingInvites,
  });

  // Return new instant invite list
  return updatedInvites;
};

export default handleMessageInstantMeetingReject;
