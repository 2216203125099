/** Errors related to using the SDK before it's active,  or an issue with
 * activating the SDK
 */
class SDKActivationError extends Error {
  constructor(message: string) {
    const errorMessage = `${message} SDK inactive or not signed into XMPP`;
    super(errorMessage);

    this.name = "SDKActivationError";
  }
}

export default SDKActivationError;
