import { DataError, SDKActivationError, MessageError } from "@chat/_errors";
import * as CTypes from "../types";
import * as _utils from "../_utils";

const sendInstantMeetingResponse = ({
  meeting_id,
  response,
  currentUser,
  isSignedIn,
  _status,
  _websocket,
  xmppServer,
  receivedInstantMeetingInvites,
}: {
  meeting_id: string;
  response: "accept" | "reject";
  currentUser: CTypes.Contact | null;
  isSignedIn: CTypes.SDK["isSignedIn"];
  _status: CTypes.LoadStatus;
  _websocket: CTypes.Websocket;
  xmppServer: CTypes.XMPPServerData;
  receivedInstantMeetingInvites: CTypes.ReceivedInstantMeetingInvite[];
}) => {
  // Validate state
  if (!isSignedIn || _status !== "active" || !_websocket) {
    throw new SDKActivationError(
      "Can't send instant meeting response, not signed in."
    );
  }
  if (!currentUser) {
    throw new DataError(
      "Can't send instant meeting response, no current user info."
    );
  }

  // Get meeting from id
  const meeting = receivedInstantMeetingInvites.filter(
    (tmpMeeting) => tmpMeeting.meeting_id === meeting_id
  )[0];

  // Ensure meeting exists
  if (!meeting) {
    throw new DataError(
      "Can't send instant meeting response, meeting doesn't exist."
    );
  }

  // Get response time and ensure it's in time
  const responseTime = new Date();
  const maximumResponseTime = new Date(meeting.maximum_response_time);
  if (responseTime > maximumResponseTime) {
    throw new MessageError(
      "Can't respond to instant meeting invite, response too late."
    );
  }

  // Create meeting response
  const instantMeetingResponse: CTypes.InstantMeetingResponse = {
    meeting_id,
    response_time: responseTime.getTime(),
  };

  // Send response
  _websocket.sendMessage({
    to: _utils.formatJID(meeting.inviter, xmppServer),
    from: currentUser._jid,
    body: JSON.stringify(instantMeetingResponse),
    // @ts-ignore
    type: `instant-meeting-${response}`,
  });
};

export default sendInstantMeetingResponse;
