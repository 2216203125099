import * as XMPP from "stanza";
import * as CTypes from "../types";
import { DataError } from "../_errors";
import * as _utils from "../_utils";

const handleStanzaGroupChat = ({
  stanza,
  currentUser,
}: {
  stanza: XMPP.Stanzas.Message;
  currentUser: CTypes.SDK["currentUser"];
}) => {
  // Validate state
  if (!currentUser) {
    throw new DataError("Can't handle chat stanza, no currentUser");
  }

  // Return converted message
  return _utils.convertMessage(stanza, currentUser.user_id);
};

export default handleStanzaGroupChat;
