import * as XMPP from "stanza";
import * as CTypes from "../types";
import { DataError } from "../_errors";
import * as _utils from "../_utils";

const handleMessageInstantMeetingInvite = ({
  message,
}: {
  message: XMPP.Stanzas.Message;
}) => {
  // Validate invite message
  if (!message.body) {
    throw new DataError("Can't handle meeting invite, no message body.");
  }

  // Convert received invite to SDK format
  const body = JSON.parse(message.body);
  const instantMeetingInvite: CTypes.ReceivedInstantMeetingInvite = {
    meeting_id: body.meeting_id,
    maximum_response_time: body.maximum_response_time,
    inviter: _utils.formatID(message.from || ""),
  };

  return instantMeetingInvite;
};

export default handleMessageInstantMeetingInvite;
