import { Amplify, Hub } from "@aws-amplify/core";
import { Auth } from "aws-amplify";

import { authStore } from "@state/store";
import { AuthenticationStore } from "@state/types";

/** Configure AWS amplify */
export const awsAmplifyConfig = () => {
  return Amplify.configure({
    Auth: {
      region: process.env.NEXT_PUBLIC_REGION,
      userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
      userPoolWebClientId: process.env.NEXT_PUBLIC_USER_POOL_WEB_CLIENT_ID,
    },
  });
};

export const setAuthListeners = (authStore: AuthenticationStore) => {
  Hub.listen("auth", (data) => {
    switch (data.payload.event) {
      case "signIn":
        console.log("user signed in");
        authStore.setIsSignedIn(true);
        break;

      case "signUp":
        console.log("user signed up");
        break;

      case "signOut":
        console.log("user signed out");
        authStore.setIsSignedIn(false);
        break;

      case "signIn_failure":
        console.log("user sign in failed");
        break;

      default:
        console.log("unknown event");
        break;
    }
  });
};
