import * as CTypes from "../types";
import * as _utils from "../_utils";
import { DataError } from "../_errors";

const createWebsocket = async ({
  _websocket,
  xmppServer,
}: {
  _websocket: CTypes.Websocket;
  xmppServer: CTypes.XMPPServerData;
}) => {
  // Validate state
  if (_websocket) {
    throw new DataError(
      "Can't create new websocket, websocket already exists."
    );
  }

  // Create websocket
  const newWebsocket = await _utils.getXMPPClient(xmppServer);

  // Return updated state
  return newWebsocket;
};

export default createWebsocket;
