/** Errors related to globalState / chat synchronization */
class SyncError extends Error {
  constructor(message: string) {
    super(message);

    this.name = "SyncError";
  }
}

export default SyncError;
