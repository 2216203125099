/** Errors related to XMPP server authentication */
class XMPPAuthError extends Error {
  constructor(message: string) {
    super(message);

    this.name = "XMPPAuthError";
  }
}

export default XMPPAuthError;
