import * as CTypes from "../types";
import * as _utils from "../_utils";

/**
 * Create single user chat rooms
 * (logic for _createSUCRooms private function)
 */
const createSUCRooms = (props: {
  contacts: CTypes.ContactList;
}): CTypes.RoomList => {
  const { contacts } = { ...props };

  // Create rooms
  const tmpRooms: CTypes.RoomList = {};
  Object.keys(contacts).forEach((contact) => {
    tmpRooms[contact] = _utils.createSUCRoom(contact);
  });

  return tmpRooms;
};

export default createSUCRooms;
