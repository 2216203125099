import * as XMPP from "stanza";
import * as CTypes from "../types";
import { DataError, MessageError } from "../_errors";
import * as _utils from "../_utils";

const onMamItem = ({
  mam,
  currentUser,
}: {
  mam: XMPP.Stanzas.ReceivedMessage;
  currentUser: CTypes.SDK["currentUser"];
}) => {
  // Validate state
  if (!currentUser) {
    throw new DataError("Can't handle mam item, no current user info.");
  }

  // Validate xmpp message and timestamp
  const rawMessage = mam.archive?.item?.message;
  const timestamp = mam.archive?.item?.delay?.timestamp;
  if (!rawMessage || !timestamp) {
    throw new MessageError(
      "Mam archive item either has no message body or timestamp."
    );
  }

  // Create SDK formatted message
  const message = _utils.convertMessage(
    rawMessage,
    currentUser.user_id,
    timestamp
  );
  return message;
};

export default onMamItem;
