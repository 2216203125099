/**
 * Format JID to Room ID or User ID
 *
 * @param {string} jid - ID or user or group chat
 *
 * @return {string} id
 */
const formatID = (jid: string) => {
  return jid?.split("@")?.[0] || jid;
};

export default formatID;
