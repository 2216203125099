import * as CTypes from "../types";
import { DataError } from "../_errors";

/** Updates array of invites with a newly updated invite after a response */
const updateInstantMeetingInvites = ({
  invite,
  invites,
}: {
  invite: CTypes.SentInstantMeetingInvite;
  invites: CTypes.SentInstantMeetingInvite[];
}) => {
  // Separate updated invite from other invites in list
  const otherInvites = invites.filter(
    (tmpInvite) => tmpInvite.meeting_id !== invite.meeting_id
  );

  // Return updated list
  const updatedInvites: CTypes.SentInstantMeetingInvite[] = [
    ...otherInvites,
    invite,
  ];
  return updatedInvites;
};

export default updateInstantMeetingInvites;
