/** Errors related to XMPP websocket */
class XMPPStreamError extends Error {
  constructor(message: string) {
    super(message);

    this.name = "XMPPStreamError";
  }
}

export default XMPPStreamError;
