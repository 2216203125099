var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/tracing";

// CONFIGURE HERE
const SENTRY_DSN =
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  "https://f0d6933febd544b79be1dd72c2a3f6cc@o1151614.ingest.sentry.io/6228558";
const SENTRY_ENVIRONMENT = process.env.NODE_ENV;
const PRODUCTION_DEBUG_MODE = false;
const DEVELOPMENT_DEBUG_MODE = false;
const PRODUCTION_SAMPLE_ERROR_RATE = 1.0;
const DEVELOPMENT_SAMPLE_ERROR_RATE = 1.0;
const PRODUCTION_TRACING_SAMPLE_RATE = 0.2;
const DEVELOPMENT_TRACING_SAMPLE_RATE = 0.2;

Sentry.init({
  dsn: SENTRY_DSN,

  // DEBUG MODE
  debug:
    SENTRY_ENVIRONMENT === "production"
      ? PRODUCTION_DEBUG_MODE
      : DEVELOPMENT_DEBUG_MODE,

  // SENTRY ENVIRONMENT SETTINGS
  environment: SENTRY_ENVIRONMENT,

  // USER FEEDBACK REPORTS SETTINGS
  beforeSend(event, hint) {
    if (event.exception && SENTRY_ENVIRONMENT === "production") {
      // IGNORE ERRORS SECTION
      if (
        event.exception &&
        event.exception.values[0].errot.text ===
          "Request timed out after 15 seconds."
      ) {
        return null;
      } else {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
    }
    return event;
  },

  //CLIENT SIDE SETTINGS
  sampleRate:
    SENTRY_ENVIRONMENT === "production"
      ? PRODUCTION_SAMPLE_ERROR_RATE
      : DEVELOPMENT_SAMPLE_ERROR_RATE,
  tracesSampleRate:
    SENTRY_ENVIRONMENT === "production"
      ? PRODUCTION_TRACING_SAMPLE_RATE
      : DEVELOPMENT_TRACING_SAMPLE_RATE,
  maxBreadcrumbs: 100,
  attachStacktrace: true,
  integrations: [new BrowserTracing()],

  // tracesSampler: ({ trace }) => {
  //   // This is a good place to filter out traces based on custom rules
  //   return trace.name.includes("react");
  // },
});
