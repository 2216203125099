import * as CTypes from "../types";
import { SDKActivationError } from "../_errors";

const connectWebsocket = ({ _websocket }: { _websocket: CTypes.Websocket }) => {
  // Validate state
  if (!_websocket) {
    throw new SDKActivationError(
      "Can't connect websocket, websocket doesn't exist."
    );
  }

  // Connect websocket
  _websocket.connect();
};

export default connectWebsocket;
