import * as ChatTypes from "../types";

/**
 * Sort messages from earliest to latest
 *
 * @param {ChatTypes.Message[]} messages - list of messages to sort
 *
 * @return {ChatTypes.Message[]} sorted messages list
 */
const sortMessages = (messages: ChatTypes.Message[]) => {
  return messages.sort((a, b) => {
    return a.timestamp.getTime() - b.timestamp.getTime();
  });
};

export default sortMessages;
