import * as XMPP from "stanza";
import * as CTypes from "../types";
import { SDKActivationError } from "../_errors";

const onSessionStarted = ({ _websocket }: { _websocket: CTypes.Websocket }) => {
  // Validate websocket
  if (!_websocket) {
    throw new SDKActivationError(
      "XMPP session can't be activated, no websocket."
    );
  }

  // Configure session
  _websocket.enableKeepAlive({ interval: 10 });
  _websocket.enableCarbons();
};

export default onSessionStarted;
