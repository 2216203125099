import { getCurrentUserInfo } from "../../@cognito";
import * as _utils from "../_utils";
import * as ChatTypes from "../types";
import { APIAuthError } from "../_errors";
import { authStore } from "@state/store";

/**
 * Retrieve current user XMPP credentials
 *
 * @param {ChatTypes.XMPPServerData} xmppServer - XMPP server config object
 *
 * @return {Promise} jid, user_id and jwt
 */
const getUserCredentials = async (
  xmppServer: ChatTypes.XMPPServerData
): Promise<{
  jid: string;
  user_id: string;
  jwt: string;
}> => {
  const { isGuest, guestID, mjwt } = authStore.getState();
  if (isGuest) {
    return {
      jid: `${guestID}@${xmppServer.hostname}`,
      user_id: guestID,
      jwt: mjwt,
    };
  }

  // Get user info
  const userInfo = await getCurrentUserInfo();
  if (!userInfo) {
    throw new APIAuthError(
      "Couldn't retrieve credentials, user not authenticated"
    );
  }

  // Return credentials
  return {
    jid: _utils.formatJID(userInfo.username, xmppServer),
    user_id: userInfo.username,
    jwt: userInfo.signInUserSession.idToken.jwtToken,
  };
};

export default getUserCredentials;
