import * as CTypes from "../types";

const onDisconnected = ({
  currentUser,
  _status,
  _websocket,
}: {
  currentUser: CTypes.SDK["currentUser"];
  _status: CTypes.LoadStatus;
  _websocket: CTypes.Websocket;
}) => {
  // Validate state
  if (!_websocket || !currentUser || _status !== "active") {
    console.log("Going ahead with disconnect");
    return true;
  }

  // Reconnect if not logging out
  else {
    console.log("User is away, reconnecting!");
    return false;
  }
};

export default onDisconnected;
